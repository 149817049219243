/* --------------------------------------------------------------------------------------
   jobStatus.service.js
   Copyright © 2024 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
   -------------------------------------------------------------------------------------
*/

export class jobStatusService {
  /*@ngInject*/
  constructor(sessionDataService, $timeout) {
    this.sessionDataService = sessionDataService;
    this.$timeout = $timeout;
  }

  getScanFinishedStatus(jobId) {
    return this.getFinishedStatus(jobId, "WorkflowScanning");
  }

  getScanJobDetails(jobId, jobType) {
    return this.getJobDetails(
      this.sessionDataService.deviceUrl,
      jobType,
      jobId
    );
  }

  getPrintFinishedStatus(jobId) {
    return this.getFinishedStatus(jobId, "Print");
  }

  async waitScan(jobId) {
    const jobDetails = await this.getJobDetails(
      this.sessionDataService.deviceUrl,
      "WorkflowScanning",
      jobId
    );
    const status = this.parseStatus(jobDetails.response);
    if (status.status == "Aborted" || status.status == "Canceled")
      // || status.status == 'ProcessingStopped')
      return Promise.reject("aborted");
    if (status.reasons == "Scanning")
      return this.$timeout(angular.noop, 2000).then(() => {
        return this.waitScan(jobId);
      });
    else return true;
  }

  async waitScanVersa(jobId) {
    const jobDetails = await this.getClientJobDetails(
      "WorkflowScanning",
      jobId
    );
    const status = this.parseStatus(jobDetails.response);
    console.log("***** VersaLink Job Status *****", status);
    if (status.status == "Aborted" || status.status == "Canceled")
      // || status.status == 'ProcessingStopped')
      return Promise.reject("aborted");
    if (status.status == "Processing")
      return this.$timeout(angular.noop, 2000).then(() => {
        return this.waitScanVersa(jobId);
      });
    else return true;
  }

  async waitProcessing(jobId) {
    const jobDetails = await this.getJobDetails(
      this.sessionDataService.deviceUrl,
      "WorkflowScanning",
      jobId
    );
    const status = this.parseStatus(jobDetails.response);

    if (status.status == "Aborted" || status.status == "Canceled")
      // || status.status == 'ProcessingStopped')
      return Promise.reject("aborted");

    if (status.reasons == "Transferring")
      return this.$timeout(angular.noop, 2000).then(() => {
        return this.waitScan(jobId);
      });
    else return true;
  }

  async getFinishedStatus(jobId, jobType) {
    const jobDetails = await this.getJobDetails(
      this.sessionDataService.deviceUrl,
      jobType,
      jobId
    );
    console.log("**** jobDetails ****", jobDetails);
    const jobState = this.getJobState(jobDetails.env, jobDetails.response);
    console.log("**** jobState ****", jobState);
    //if (jobState == "ProcessingStopped") return Promise.reject()
    if (
      !(
        jobState == "Aborted" ||
        jobState == "Canceled" ||
        jobState == "ProcessingStoppedNOT" ||
        jobState == "Completed"
      )
    ) {
      return this.$timeout(angular.noop, 2000).then(() => {
        return this.getFinishedStatus(jobId, jobType);
      });
    } else {
      return jobState;
    }
  }

  async getFinishedStatusByClientId(jobId, jobType) {
    const jobDetails = await this.getClientJobDetails(jobType, jobId);
    console.log("**** jobDetails ****", jobDetails);
    const jobState = this.getJobState(jobDetails.env, jobDetails.response);
    console.log("**** jobState ****", jobState);
    //if (jobState == "ProcessingStopped") return Promise.reject()
    if (
      !(
        jobState == "Aborted" ||
        jobState == "Canceled" ||
        jobState == "ProcessingStoppedNOT" ||
        jobState == "Completed"
      )
    ) {
      return this.$timeout(angular.noop, 2000).then(() => {
        return this.getFinishedStatusByClientId(jobId, jobType);
      });
    } else {
      return jobState;
    }
  }

  listActiveJobs() {
    const deviceUrl = this.sessionDataService.deviceUrl;
    return new Promise(function (resolve, reject) {
      xrxJobMgmtListActiveQueue(
        deviceUrl,
        (response) => {
          console.log(response);
          resolve(xrxJobMgmtParseListActiveQueue(response));
        },
        (errorResponse) => reject(errorResponse),
        10,
        true
      );
    });
  }

  parseStatus(jobDetailsResponse) {
    const jobDetails = xrxJobMgmtParseGetJobDetails(jobDetailsResponse);
    const jobStateNode = xrxFindElement(jobDetails, ["JobInfo", "JobState"]);
    const jobStateReasonsNode = xrxFindElement(jobDetails, [
      "JobInfo",
      "JobStateReasons",
    ]);
    return {
      status: xrxGetValue(jobStateNode),
      reasons: xrxGetValue(jobStateReasonsNode),
    };
  }

  getJobState(env, response) {
    const jobDetails = xrxJobMgmtParseGetJobDetails(response);
    const jobStateNode = xrxFindElement(jobDetails, ["JobInfo", "JobState"]);
    const jobState = xrxGetValue(jobStateNode);
    //var jobStateFormatted = jobState.replace("Held", "").replace("Stopped", "");
    return jobState;
  }

  async getJobStatus(jobId) {
    //alert open
    const jobDetails = await this.getJobDetails(
      this.sessionDataService.deviceUrl,
      "WorkflowScanning",
      jobId
    );
    return this.jobStatusSuccess(jobDetails.env, jobDetails.response);
  }

  getJobDetails(deviceUrl, jobType, jobId) {
    return new Promise((resolve, reject) => {
      xrxJobMgmtGetJobDetails(
        deviceUrl,
        jobType,
        jobId,
        (env, response) => resolve({ env, response }),
        (env, response) => reject({ method: "Get Job Details", env, response })
      );
    });
  }

  getClientJobDetails(jobType, jobId) {
    const deviceUrl = this.sessionDataService.deviceUrl;
    return new Promise((resolve, reject) => {
      xrxJobMgmtGetClientJobDetails(
        deviceUrl,
        jobType,
        jobId,
        (env, response) => resolve({ env, response }),
        (env, response) => reject({ method: "Get Job Details", env, response })
      );
    });
  }

  cancelScan(jobId) {
    return this.cancelJob("WorkflowScanning", jobId);
  }

  resumeJob(jobType, jobId) {
    return new Promise((resolve, reject) => {
      xrxJobMgmtResumeJobSecure(
        this.sessionDataService.deviceUrl,
        jobType,
        jobId,
        "admin",
        "1111",
        (env, response) => resolve({ env, response }),
        (env, response) => reject({ method: "Cancel Job", env, response })
      );
    });
  }

  cancelJob(jobType, jobId) {
    return new Promise((resolve, reject) => {
      xrxJobMgmtCancelJob(
        null,
        jobType,
        jobId,
        (env, response) => resolve({ env, response }),
        (env, response) => reject({ method: "Cancel Job", env, response }),
        10
      );
    });
  }
}
