/* --------------------------------------------------------------------------------------
landing.component.js
   Copyright © 2024 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
--------------------------------------------------------------------------------------*/

export const landingComponent = {
  template: `
        <activity display="vm.loading" label="{{ 'LOADING_SUMMARIZER' | translate }}" on-close="vm.onLoadingClose()"></activity>
        <div
          xw-alert
          display="vm.maintenance"
          class="maintenance-indicator"
          close-button
          on-close="vm.exitApp()"
        >
            <div class="center-activity">
                <div xw-activity widget-size="xrx-large"></div>
            </div>
            <p class="white-text"><strong>{{ 'INSTALLING_APP_UPDATES' | translate }}</strong></p>
            <div class="error-description">
                <p class="activity-subtitle">{{ 'THIS_MAY_TAKE_SEVERAL_MINUTES' | translate }}</p>
                <p class="activity-subtitle">{{ 'CLOSE_ALERT_CONTINUE_WORKING' | translate }}</p>
            </div>
        </div>
        <div class="hidden-maintenance-override" ng-show="vm.maintenance" ng-click="vm.maintenanceOff()"></div>
        <error-message
          ng-if="vm.errorType"
          error-type="vm.errorType"
          on-close="vm.onErrorClose()"
        ></error-message>
    `,
  controllerAs: "vm",
  controller: class landingController {
    /* @ngInject */
    constructor(
      apiClientService,
      webServiceStatusService,
      shellDataBagService,
      commonService,
      scanService,
      errorService,
      deviceInfoService,
      $state
    ) {
      this.apiClientService = apiClientService;
      this.webServiceStatusService = webServiceStatusService;
      this.shellDataBagService = shellDataBagService;
      this.commonService = commonService;
      this.scanService = scanService;
      this.deviceInfoService = deviceInfoService;
      this.errorService = errorService;
      this.$state = $state;
    }
    async $onInit() {
      this.loading = true;
      const eipStatus = await this.webServiceStatusService.getStatus();

      if (eipStatus.some((s) => !s)) {
        const keys = [];
        if (!eipStatus[0]) {
          keys.push("DEVICE_CONFIGURATION");
        }
        if (!eipStatus[1]) {
          keys.push("JOB_MANAGEMENT_EXTENSION");
        }
        if (!eipStatus[2]) {
          keys.push("SCAN_TEMPLATE_MANAGEMENT");
        }
        if (!eipStatus[3]) {
          keys.push("SCAN_EXTENSION");
        }
        keys.push("CONTACT_ADMINISTRATOR_TO_ENABLE2")
        this.errorType = {
          titleKey: "EIP_SERVICES",
          keys: keys,
          exitApp: true,
        };

        this.onErrorClose = this.errorService.getErrorCallback(this.errorType);
        this.loading = false;
        this.commonService.updateView();

        return;
      }

      const isInMaintainanceMode = await this.getMaintenanceModeValue();
      this.loading = false;
      if (isInMaintainanceMode) {
        this.maintenance = true;
        this.onLoadingClose = () =>
          setInterval(() => this.getAvailability(), 3000);
      } else {
        this.onLoadingClose = async () => {
          this.loading = true;
          this.commonService.updateView();
          const isAltaLink = await this.commonService.isAltalink();
          try {
            await this.deviceInfoService.getDeviceCapabilities();
            await this.scanService.setOptions(isAltaLink);
            this.shellDataBagService.setData();
          } catch (error) {
            this.errorType = error.titleKey
              ? error
              : this.errorService.getErrorByStatusCode(error.status);
            this.onErrorClose = () => {
              this.errorService.getErrorCallback(this.errorType)();
            };
          } finally {
            this.commonService.updateView();
          }
          const params = this.shellDataBagService.getParamObject();
          if(!params.apiKey){
            const apiKeyError = this.errorService.getApiKeyError();
            this.commonService.updateView();
            this.$state.go("error",{errorType: apiKeyError, onClose: ()=>this.exitApp()});
          }
          else {
            this.loading = false;
            this.commonService.updateView();
            this.$state.go("eula");
          }
        };
      }
      this.commonService.updateView();
    }
    async getAvailability() {
      const isInMaintainanceMode = await this.getMaintenanceModeValue();
      if (!isInMaintainanceMode) this.maintenanceOff();
    }
    maintenanceOff() {
      this.maintenance = false;
      this.onMaintenanceClose = () => this.$state.go("eula");
    }
    async getMaintenanceModeValue() {
      const maintenanceConfig = await this.apiClientService.$http.get(
        "maintenance.json"
      );
      return maintenanceConfig.data.maintenanceMode;
    }
    exitApp() {
      this.commonService.exitApp();
    }
  },
};
