/* --------------------------------------------------------------------------------------
  scan.service.js
  Copyright © 2024 Xerox Corporation. All Rights Reserved.

  Copyright protection claimed includes all forms and matters of copyrightable material
  and information now allowed by statutory or judicial law or hereinafter granted,
  including without limitation, material generated from the software programs which
  are displayed on the screen such as icons, screen display looks, etc.
  -------------------------------------------------------------------------------------
*/

export class scanService {
  /* @ngInject*/
  constructor(
    xeroxEIPParameters,
    languagesService,
    deviceInfoService,
    apiClientService,
    workflowScanningService,
    sessionDataService,
    $translate
  ) {
    this.xeroxEIPParameters = xeroxEIPParameters
    this.languagesService = languagesService
    this.apiClientService = apiClientService
    this.deviceInfoService = deviceInfoService
    this.workflowScanningService = workflowScanningService
    this.sessionDataService = sessionDataService
    this.$translate = $translate
  }

  async setOptions(isAlta) {
    this.twoSidedScanOptions = this.xeroxEIPParameters.sides.filter(
      (side) =>
        side.scope.includes("scan") &&
        side.device.includes(isAlta ? "Alta" : "Versa")
    )

    this.originalOrientationOptions = this.xeroxEIPParameters.orientations
    this.originalTypeOptions = this.xeroxEIPParameters.types
    this.originalSizeOptions = isAlta
    ? this.xeroxEIPParameters.sizes
    : this.xeroxEIPParameters.sizes.filter((s) => !s.a3);

    await this.setDefaults()
  }

  async setDefaults() {
    this.twoSidedScan = this.twoSidedScanOptions[0].key
    this.twoSidedScanImage = this.twoSidedScanOptions[0].img
    this.twoSidedScanText = await this.$translate(
      this.twoSidedScanOptions[0].translationKey
    )

    this.originalOrientation = this.originalOrientationOptions[0].key
    this.originalOrientationImage = this.originalOrientationOptions[0].img
    this.originalOrientationText = await this.$translate(
      this.originalOrientationOptions[0].translationKey
    )

    this.originalType = this.originalTypeOptions[0].key
    this.originalTypeImage = this.originalTypeOptions[0].img
    this.originalTypeText = await this.$translate(
      this.originalTypeOptions[0].translationKey
    )

    this.originalSize = this.originalSizeOptions[2].key
    this.originalSizeText = this.originalSizeOptions[2].value
    this.originalSizeOrientation = this.originalSizeOptions[2].orientation
  }

  async setDefaultLanguage() {
    if (!this.languages) this.languages = this.languagesService.getLanguages()
    this.language = this.languages[4]
    this.searchable = true
  }

  async scan(data) {
    try {
      const scanTicket = await this.apiClientService.post("/scan/ticket", data)
      const scanJob = await this.workflowScanningService.submitScanTicket(
        scanTicket,
        this.sessionDataService.deviceUrl
      )
      return xrxScanV2ParseInitiateScanJob(scanJob.response)
    } catch (error) {
      throw error
    }
  }

  async getUploadStatus(scanProfileId) {
    try {
      const fn = () =>
        this.apiClientService.get(`/scan/complete/${scanProfileId}`)
      const fnCondition = (result) => result !== true
      const scanStatus = await this.poll(fn, fnCondition, 1000)
      return scanStatus
    } catch (error) {
      throw error
    }
  }

  async getScanPagesCount(scanProfileId) {
    try {
      const pages = await this.apiClientService.get(
        `/scan/pages/${scanProfileId}`
      )
      return pages
    } catch (error) {
      throw error
    }
  }

  async getDocumentSendStatus(scanProfileId) {
    try {
      return this.apiClientService.get(`/v1/status?token=${scanProfileId}`)
    } catch (error) {
      throw error
    }
  }

  async poll(fn, fnCondition, ms) {
    let result = await fn()
    while (fnCondition(result)) {
      await this.wait(ms)
      result = await fn()
    }
    return result
  }

  wait(ms = 1000) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms)
    })
  }
}
