/* --------------------------------------------------------------------------------------
   persist.service.js
   Copyright © 2024 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
   -------------------------------------------------------------------------------------
*/

export class persistService {
    /*@ngInject*/
  constructor ($window, $cookies, moment) {
    this.$window = $window
    this.moment = moment
    this.$cookies = $cookies
    this.storage = this.$window.localStorage
  }
  setObject(key, value, cookie) {
    this.setString(key, angular.toJson(value), cookie)
  }
  getObject(key, cookie) {
    return angular.fromJson(this.getString(key, cookie))
  }
  setMoment(key, value, cookie) {
    this.setString(key, value.format(), cookie)
  }
  getMoment(key, cookie) {
    return this.moment(this.getString(key, cookie))
  }
  setString(key, value, cookie) {
    if (cookie) {
      if (value)
        this.$cookies.put(key, value)
      else
        this.$cookies.remove(key)
    }
    else
      this.storage.setItem(key, value)
    //TODO: Remove item when null
  }
  getString(key, cookie) {
    if (cookie)
      return this.$cookies.get(key)
    else
      return this.storage.getItem(key)
  }
}
