/* --------------------------------------------------------------------------------------
   eCommerceBalance.component.js
   Copyright © 2024 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
   -------------------------------------------------------------------------------------
*/

export const eCommerceBalanceComponent = {
    template: '<p ng-class="vm.cssClass"><span translate>BALANCE</span>: {{ vm.balance }} <span translate>{{vm.unit}}</span></p>',
    controllerAs: 'vm',
    controller: class eCommerceBalanceCtrl {
      /* @ngInject*/
      constructor(ecommerceService, commonService) {
        this.ecommerceService = ecommerceService
        this.commonService = commonService
      }
      async $onInit() {
        try {
          this.balance = await this.ecommerceService.getBalance()          
        } catch (error) {
          this.onError()
        }
        if (Number.isNaN(this.balance))
          this.onError()
        await this.commonService.updateView()
        this.onLoad()
      }
    },
    bindings: {
        balance: '=',
        cssClass: '@',
        unit: '@',
        onError: '&',
        onLoad: '&'
    }
  }
  