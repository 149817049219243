/* --------------------------------------------------------------------------------------
   home.component.js
   Copyright © 2024 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
   -------------------------------------------------------------------------------------
*/

export const homeComponent = {
  template: require("./home.html"),
  controllerAs: "vm",
  controller: class homeController {
    /* @ngInject */
    constructor(
      commonService,
      scanService,
      homeService,
      errorService,
      eCommerceService,
      shellDataBagService,
      $state,
      $window
    ) {
      this.commonService = commonService
      this.scanService = scanService
      this.homeService = homeService
      this.eCommerceService = eCommerceService
      this.errorService = errorService
      this.shellDataBagService = shellDataBagService
      this.$state = $state
      this.$window = $window
      this.localStorage = this.$window.localStorage
    }

    async $onInit() {
      try {
        this.screenSize = this.commonService.getScreenSize()
        this.boundingBoxHeight = this.screenSize === 7 ? 370 : 472
        this.boundingBoxWidth = this.screenSize === 7 ? 278 : 350
        this.showPreviewPopup = false

        this.shellValues = this.shellDataBagService.getData()
        const { appId, deviceSN } = this.shellValues
        this.homeService.deviceSN = deviceSN
        this.homeService.appId = appId

        this.loading = true
        this.commonService.updateView()

        const eCommerceSummary =
          await this.eCommerceService.getEcommerceSummary(
            this.homeService.deviceSN,
            this.homeService.appId
          )
        this.isEntitled = eCommerceSummary.isEntitled
        this.entitledStatusCode = eCommerceSummary.entitledStatusCode
        this.remainingDays = eCommerceSummary.remainingDays

        if (!this.isEntitled && this.entitledStatusCode === 6) {
          this.loading = false
          this.errorType = this.errorService.errorTypes.appUnavailable
          this.onErrorClose = () => {
            this.errorService.getErrorCallback(this.errorType)()
          }
          this.commonService.updateView()
          return false
        }

        if (
          !this.isEntitled &&
          this.entitledStatusCode === 7 &&
          this.remainingDays >= 0
        ) {
          this.loading = false
          this.errorType = this.errorService.errorTypes.appUnavailable
          this.onErrorClose = () => {
            this.errorService.getErrorCallback(this.errorType)()
          }
          this.commonService.updateView()
          return false
        }

        if (
          !this.isEntitled &&
          this.entitledStatusCode !== 7 &&
          this.remainingDays >= 0
        ) {
          this.loading = false
          this.errorType = this.errorService.errorTypes.appUnavailable
          this.onErrorClose = () => {
            this.errorService.getErrorCallback(this.errorType)()
          }
          this.commonService.updateView()
          return false
        }

        this.remainingCredits = await this.eCommerceService.getEcommerceUsage(
          this.homeService.deviceSN,
          this.homeService.appId
        )

        if (this.remainingCredits === -1) {
          this.eCommerceService.isEnterpriseModel = true
          this.commonService.updateView()
        }

        this.loading = false
        this.commonService.updateView()

        setTimeout(() => {
          angular
            .element("#summarizer-length-select-menu li")
            .removeClass("ui-state-focus ui-state-selected")
          angular
            .element("#summarizer-length-select-menu li")
            .eq(1)
            .addClass("ui-state-selected")

          angular
            .element("#summarizer-domain-select-menu li")
            .removeClass("ui-state-focus ui-state-selected")
          angular
            .element("#summarizer-domain-select-menu li")
            .eq(1)
            .addClass("ui-state-selected")

          const summarizationDomainIndex = parseInt(
            this.localStorage.getItem("domain-index")
          )
          const summarizationDomainText =
            this.localStorage.getItem("domain-text")

          if (!!summarizationDomainIndex && !!summarizationDomainText) {
            this.homeService.summarizationDomainIndex = summarizationDomainIndex
            this.homeService.summarizationDomainText = summarizationDomainText
            this.homeService.summarizationDomain =
              summarizationDomainIndex > 6
                ? this.homeService.additionalDomainOptions[
                    summarizationDomainIndex - 7
                  ].value
                : this.homeService.commonDomainOptions[summarizationDomainIndex]
                    .value
            const selectedIndex =
              this.homeService.summarizationDomainIndex > 6
                ? this.homeService.summarizationDomainIndex + 2
                : this.homeService.summarizationDomainIndex + 1
            angular
              .element("#summarizer-domain-select-menu li")
              .removeClass("ui-state-focus ui-state-selected")
            angular
              .element("#summarizer-domain-select-menu li")
              .eq(selectedIndex)
              .addClass("ui-state-selected")
            this.commonService.updateView()
          }

          $("#summarizer-domain-select").xrxsegmentedcontrol({
            select: (event, ui) => {
              this.homeService.summarizationDomainIndex = ui.item.index
              this.homeService.summarizationDomain =
                ui.item.index > 6
                  ? this.homeService.additionalDomainOptions[ui.item.index - 7]
                      .value
                  : this.homeService.commonDomainOptions[ui.item.index].value
              const domainText =
                ui.item.index > 6
                  ? this.homeService.additionalDomainOptions[ui.item.index - 7]
                      .label
                  : this.homeService.commonDomainOptions[ui.item.index].label
              this.homeService.summarizationDomainText = domainText
              this.localStorage.setItem("domain-index", ui.item.index)
              this.localStorage.setItem("domain-text", domainText)

              const selectedIndex =
                this.homeService.summarizationDomainIndex > 6
                  ? this.homeService.summarizationDomainIndex + 2
                  : this.homeService.summarizationDomainIndex + 1
              angular
                .element("#summarizer-domain-select-menu li")
                .removeClass("ui-state-focus ui-state-selected")
              angular
                .element("#summarizer-domain-select-menu li")
                .eq(selectedIndex)
                .addClass("ui-state-selected")
              this.commonService.updateView()
            }
          })

          this.commonService.updateView()
        }, 500)
      } catch (error) {
        this.showCustomError(error)
        this.loading = false
        this.commonService.updateView()
      }
    }

    get checkDisableOkButton() {
      return (
        (!this.homeService.printResults && !this.homeService.emailResults) ||
        (this.homeService.emailResults &&
          this.homeService.recipients.length === 0)
      )
    }

    get checkDisableOkButtonStyle() {
      return (
        (!this.homeService.printResults && !this.homeService.emailResults) ||
        (this.homeService.emailResults &&
          this.homeService.recipients.length === 0)
      )
    }

    async handleEcommerceTrack() {
      const pageCount = await this.scanService.getScanPagesCount(
        this.scanProfileId
      )

      const creditsRequired = this.homeService.summarizationTypeExtractive
        ? 1 * pageCount
        : 2 * pageCount

      await this.eCommerceService.postEcommerceTrack(
        this.homeService.deviceSN,
        this.homeService.appId,
        "credits",
        creditsRequired
      )
      this.remainingCredits = this.remainingCredits - creditsRequired
      this.commonService.updateView()
    }

    async ok() {
      if (!this.homeService.printResults && !this.homeService.emailResults) {
        return
      }

      try {
        if (
          this.homeService.emailResults &&
          this.homeService.recipients.length === 0
        ) {
          return
        }
        this.isProcessing = true
        this.processingLabel = "SCANNING"
        this.commonService.updateView()

        this.scanProfileId = this.commonService.getNewGuid()

        await this.homeService.scan(this.scanProfileId)

        this.processingLabel = "PROCESSING"

        await this.homeService.getSummaryPreviewData(this.scanProfileId)

        if (this.homeService.togglePreview) {
          this.handleEcommerceTrack()
        }

        this.isProcessing = false
        this.commonService.updateView()

        if (this.homeService.togglePreview) {
          this.showPreviewPopup = true
          this.commonService.updateView()
        }

        if (!this.homeService.togglePreview) {
          if (this.homeService.printResults) {
            this.homeService.isPrinting = true
            this.homeService.printingLabel = "PRINTING"
            this.commonService.updateView()
          }

          if (this.homeService.emailResults) {
            this.homeService.isPrinting = true
            this.homeService.printingLabel = "EMAILING"
            this.commonService.updateView()
          }

          if (this.homeService.printResults && this.homeService.emailResults) {
            this.homeService.isPrinting = true
            this.homeService.printingLabel = "EMAIL_PRINT"
            this.commonService.updateView()
          }

          const blobUrl = await this.homeService.sendSummarizationEmail()

          this.commonService.updateView()

          if (this.homeService.printResults) {
            await this.homeService.print(blobUrl.url)
            this.homeService.isPrinting = false
            this.commonService.updateView()
          }

          if (!this.homeService.togglePreview) {
            this.handleEcommerceTrack()
          }

          this.homeService.isPrinting = false
          this.commonService.updateView()
        }
      } catch (error) {
        this.isProcessing = false
        this.homeService.isPrinting = false
        this.showCustomError(error)
        this.commonService.updateView()
      }
    }

    showCustomError(error) {
      this.errorType = this.errorService.getErrorByStatusCode(error.status)
      this.onErrorClose = () => {
        this.errorService.getErrorCallback(this.errorType)()
      }
    }

    async reset() {
      this.resetBanner = true
      await this.homeService.setDefaults()
      await this.scanService.setDefaults()

      angular
        .element("#summarizer-length-select-menu li")
        .removeClass("ui-state-focus ui-state-selected")
      angular
        .element("#summarizer-length-select-menu li")
        .eq(1)
        .addClass("ui-state-selected")

      angular
        .element("#summarizer-domain-select-menu li")
        .removeClass("ui-state-focus ui-state-selected")
      angular
        .element("#summarizer-domain-select-menu li")
        .eq(1)
        .addClass("ui-state-selected")
      this.commonService.updateView()
    }

    refreshBoundingBox() {
      setTimeout(async () => {
        angular.element("#home-screen-scroll").xrxscrollable({
          scroll: true,
          height: this.boundingBoxHeight,
          width: this.boundingBoxWidth
        })
      })
    }

    getOrientationGlyph(orientation) {
      switch (orientation) {
        case "L":
          return "glyphicon-short-edge-feed"
        case "P":
          return "glyphicon-long-edge-feed"
        default:
          return null
      }
    }

    async openResetBanner() {
      await this.reset()
      angular.element("#reset-banner").xrxbanner("call")
    }

    selectSummarizationLength(item) {
      angular
        .element("#summarizer-length-select-menu li")
        .removeClass("ui-state-focus ui-state-selected")
      angular
        .element("#summarizer-length-select-menu li")
        .eq(item.index)
        .addClass("ui-state-selected")
      this.homeService.summarizationLengthIndex = item.index
      this.homeService.summarizationLengthValue =
        this.homeService.summarizationLengthOptions[item.index].value
      this.homeService.summarizationLengthText =
        this.homeService.summarizationLengthOptions[item.index].label
      this.commonService.updateView()
    }

    selectTwoSidedScan(item) {
      this.scanService.twoSidedScan =
        this.scanService.twoSidedScanOptions[item.index].key
      this.scanService.twoSidedScanText = item.label
      this.commonService.updateView()
    }

    selectFileExtension(item) {
      const previousExtension = this.homeService.fileExtensionText
      this.homeService.fileExtension =
        this.homeService.fileExtensionOptions[item.index].key
      this.homeService.fileExtensionText =
        this.homeService.fileExtensionOptions[item.index].label
      let fileName = this.homeService.filename
      fileName = fileName.replace(
        previousExtension,
        this.homeService.fileExtensionText
      )
      this.homeService.filename = fileName
      this.refreshBoundingBox()
      this.commonService.updateView()
    }

    selectOriginalSize(item) {
      const selectedSize = this.scanService.originalSizeOptions[item.index]
      this.scanService.originalSize = selectedSize.key
      this.scanService.originalSizeText = selectedSize.value
      this.scanService.originalSizeOrientation = selectedSize.orientation
      this.commonService.updateView()
    }
  }
}
