/* --------------------------------------------------------------------------------------
   listSelectorPopover.component.js
   Copyright © 2024 Xerox Corporation. All Rights Reserved.

   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
   -------------------------------------------------------------------------------------
*/

export const listSelectorPopoverComponent = {
    template: require('./listSelectorPopover.html'),
    controllerAs: 'vm',
    bindings: {
      fieldName: '<',
      items: '<',
      model: '=',
      onSelect: '&'
    },
    controller: class listSelectorPopoverCtrl {
      /* @ngInject*/
      constructor(commonService, general) {
        this.commonService = commonService
        this.general = general
      }
      $onInit() {
        this.targetControlLeft = '#' + this.getColumnId('left')
        this.targetControlRight = '#' + this.getColumnId('right')   
        this.screenSize = this.commonService.getScreenSize()
        this.maxVisibleElements = this.screenSize == 7? 6 : 9
        this.height = this.getHeight()
      }
      getColumnId(side) {
        return 'list-selector-' + this.fieldName.replace(' ','-').toLowerCase() + '-' + side
      }
      getHeight() {
        //We can do this better
        const result = this.general.popoverHeights.filter(h => h.items == this.items.length)
        if (!result)
          result = this.general.popoverHeights.filter(h => h.default)
        return result
      }
      getSegmentedHeight() {
        const rowHeight =  this.screenSize == 7? 69 : 63
        return Math.min(this.items.length, this.maxVisibleElements) * rowHeight
      }
      isScrollable() {
        return this.items.length > this.maxVisibleElements
      }
      select(item) {
        this.model = { key: item.value, value: item.label }
        this.commonService.updateView()
        this.onSelect()
      }
    }
  }
  