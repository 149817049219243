/* --------------------------------------------------------------------------------------
privacyStatement.component.js
   Copyright © 2024 Xerox Corporation. All Rights Reserved.
     
   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
--------------------------------------------------------------------------------------*/

export const privacyStatementComponent = {
  template: require("./privacyStatement.component.html"),
  controllerAs: "vm",
  controller: class privacyStatementCtrl {
    /* @ngInject*/
    constructor (
      appSettings,
      commonService,
      $state,
      disclaimerService,
      $sce,
      $stateParams,
      disclaimerTypes,
      $translate
    ) {
      this.appSettings = appSettings
      this.commonService = commonService
      this.disclaimerService = disclaimerService
      this.$state = $state
      this.$sce = $sce
      this.eulaAccepted = true
      this.disclaimerTypes = disclaimerTypes
      this.$translate = $translate
      this.firstTimeAfterEula = $stateParams.isFirstTimeAfterEula
    }

    $onInit () {
      this.screenSize = this.commonService.getScreenSize()
      this.display = true
    }

    onClose () {
      this.commonService.exitApp()
    }
    onAccept () {
      this.$state.go("home")
    }

    openPrivacyPopup(){
      this.openDisclaimerPopup(this.disclaimerTypes.privacyStatement)
    }
    openEulaPopup(){
      this.openDisclaimerPopup(this.disclaimerTypes.eula)
    }
    async openDisclaimerPopup (disclaimerType) {
      this.titleKey = disclaimerType === this.disclaimerTypes.privacyStatement ? "PRIVACY.POPUP_TITLE" : "EULA.TITLE";
      this.disclaimerPopup = true
      this.loadingDisclaimer = true;
      (disclaimerType === this.disclaimerTypes.privacyStatement
        ? this.disclaimerService.getPrivacyStatement(
            this.commonService.getNavigatorLanguage()
          )
        : this.disclaimerService.getEula(
            this.commonService.getNavigatorLanguage()
          )
      )
        .then(r => {
          this.disclaimerContent = this.renderHTML(r.data)
          this.loadingDisclaimer = false
          setTimeout(async () => {
            angular.element("#disclaimer-scroll").xrxscrollable({
              scroll: true,
              height: this.screenSize == 7 ? 390 : 504,
              width: this.screenSize == 7 ? 788 : 1006
            })
          })
          this.commonService.updateView()
        })
        .catch(err => {
          this.loadingDisclaimer = false
          this.disclaimerPopup = false
          //set error here
          //setError(getErrorByStatusCode(err.response.status))
        })
      document.activeElement.blur()
      this.commonService.updateView()
    }

    closeDisclaimerPopup () {
      this.disclaimerContent = ""
      //reset scroll position
      angular.element("#disclaimer-scroll").xrxscrollable('destroy')
      document.activeElement.blur()
      this.disclaimerPopup = false
      this.commonService.updateView()
    }

    renderHTML (html_code) {
      var decoded = angular.element("<textarea />").html(html_code).text()
      return this.$sce.trustAsHtml(decoded)
    }
  },
  bindings: {
    onOk: "&"
  }
}
